














































































































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import CustomContributionsViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/custom/custom-contributions-view-model';

@Component({
  name: 'CustomContributions',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class CustomContributions extends Vue {
  view_model = Vue.observable(
    new CustomContributionsViewModel(this),
  );

  async created() {
    await this.view_model.initialize();
  }
}
